import "./productandservice.css";
import { Img } from "../../components/Image";
import Visionary from "./Visionary";
import PageSegment from "../../components/PageSegment/PageSegment";
import Insights from "./Insights";
import Footer from "../../components/Footer/Footer";
import { useState } from "react";

const Service = () => {
  // const[CardOneCss,CardTwoCss]=useState({
  //   background: "linear-gradient(180deg, #471846 0%, #0F112A 100%)",
  //   boxShadow: "0px 3px 49px 9px rgba(0, 0, 0, 0.06)",
  //   paddingTop: "2rem",
  //   paddingInline: "2rem",

  // })
  setInterval(() => {});
  return (
    <div className="Our-Services">
      <PageSegment name="Product & Services" />
      <div className="Our-Service-Cards">
        <div className="Our-Services-Card1">
          <Img
            src="/images/serviceImg1.png"
            width="88px"
            height="88.813px"
            className="CardImg1"
          />
          <div className="Card1-Header">
            <div>Visionary Insights, Your Digital</div>
            <div>Eyes</div>
          </div>
          <div className="Card1-Text">
            <div>
              Be effortlessly vigilant with our tracking system, deploying
              cutting-edge models for top-notch accuracy in monitoring and
              providing valuable visual insights.
            </div>
            <div>
              Transform your surveillance with our advanced multimodal cameras,
              offering unparalleled multiview and 3D tracking capabilities. We
              utilize state-of-the-art models for superior accuracy.
            </div>
            <div>
              Enhance security and efficiency using our tracking solutions.
              Deploying cutting-edge models, we deliver precise monitoring and
              actionable visual insights for informed decision-making
            </div>
          </div>
        </div>
        <div className="Our-Services-Card2">
          <Img
            src="images/vision1.svg"
            width="88px"
            height="88.813px"
            className="CardImg1"
          />
          <div className="Card2-Header">
            <div> Revolutionizing Enterprise</div>
            <div> Knowledge Retrieval</div>
          </div>
          <div className="Card2-Text">
            <div>
              We believe in making knowledge retrieval seamless as a google
              search leveraging Generative AI and LLM models.
            </div>
            <div>
              We envision a future where every enterprise, regardless of its
              size or industry, can effortlessly tap into its vast knowledge
              base.
            </div>
            <div>
              Say goodbye to hours spent searching for information. With GenAI,
              you get what you need in seconds
            </div>
          </div>
        </div>
      </div>
      <Visionary />
      <Insights />
      <Footer />
    </div>
  );
};

export default Service;
