import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Img } from "../Image";
import "./profileCard.css";

const ProfileCard = (props) => {
  return (
    <div className="ProfileContainer">
      <div className="Profile">
        <Img src={"images/" + props.data["Image"]} />
      </div>
      <div className="ProfileContent">
        <div className="ProfileInfo">
          <div className="ProfileTitle">
            <div className="ProfileName">{props.data["Name"]}</div>
            <div className="ProfileJob">{props.data["Role"]}</div>
          </div>
          <div className="ProfileText">{props.data["Info"]}</div>
        </div>
        <div className="ProfileSocialMedia">
          <FontAwesomeIcon
            className="MediaIcons"
            icon={faFacebook}
            size="2xl"
            color="#74C0FC"
          />
          <FontAwesomeIcon
            icon={faInstagram}
            className="MediaIcons"
            size="2xl"
            color=" #8a3ab9"
          />
          <FontAwesomeIcon icon={faTwitter} className="MediaIcons" size="2xl" />
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
