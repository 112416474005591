import { Img } from "../../components/Image";

const Visionary = () => {
  return (
    <div className="Our-Visionary">
      <div className="Visionary-ImgContainer">
        <Img src="images/potentialImg.svg" />
      </div>
      <div className="Our-Potential">
        <Img
          src="images/potentialImg2.png"
          width="88px"
          height="88.813px"
          className={"ZoomEye"}
        />
        <div className="Potential-Header">Kaytie - Enterprise LLM</div>
        <div className="Potential-Text">
          <div>Customized Enterprise knowledge sharing Service built using</div>
          <div>Generative AI & Language models.</div>
        </div>
        <ul className="Potential-Bullets">
          <li> Faster and efficient Knowledge retrieval</li>
          <li>
            Democratization of business knowledge within teams / organizations /
            Enterprises
          </li>
          <li>
            Personalised information retreival based on User’s access to docs.
          </li>
          <li>Tagging the sources with document timelines and versions.</li>
          <li>
            Can retrieve information from variety of sources like pdfs, word,
            csv, SQL and other databases.
          </li>
        </ul>
        <a
          href="/Products/kaytie"
          style={{ textDecoration: "none" }}
          className="KaytieButton"
        >
          Read More
        </a>
      </div>
    </div>
  );
};

export default Visionary;
