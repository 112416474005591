import React, { useEffect, useState } from "react";
import { Img } from "../Image";
import "./header.css";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const [navOption, navOptionSet] = useState(false);
  const nav = useNavigate();

  const pages = ["Home", "About", "Services", "Blogs", "Contact"];
  return (
    <div className="header-Container">
      <div className="header-Wrapper">
        <Img
          className="logo"
          onClick={() => {
            nav("/Home");
          }}
          src="/images/altgan-logo.svg"
          height="50px"
          width="186px"
        />
        <nav className="header-nav">
          <ul>
            {pages.map((pages) => (
              <li>
                <NavLink
                  id="NavButtons"
                  to={`/${pages}`}
                  style={({ isActive }) => {
                    return isActive
                      ? {
                          // background:
                          //   "linear-gradient(96deg, #EDB89C 0.58%, #7769E6 22.05%)",
                          // backgroundClip: "text",
                          // WebkitBackgroundClip: "text",
                          // WebkitTextFillColor: "transparent",
                          color: "rgb(237, 184, 156)",
                        }
                      : {};
                  }}
                >
                  {pages}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
        <div className="MenuBar-Wrapper">
          <FontAwesomeIcon
            icon={faBars}
            size="2x"
            color="white"
            className="MenuBars"
            onClick={() => {
              navOptionSet((prev) => {
                return !prev;
              });
            }}
          />
        </div>
      </div>
      {navOption ? (
        <nav className="header-nav2">
          <ul>
            {pages.map((pages) => (
              <li>
                <NavLink
                  id="NavButtons"
                  to={`/${pages}`}
                  onClick={() => {
                    navOptionSet((prev) => {
                      return !prev;
                    });
                  }}
                  style={({ isActive }) => {
                    return isActive
                      ? {
                          // background:
                          //   "linear-gradient(96deg, #EDB89C 0.58%, #7769E6 22.05%)",
                          // backgroundClip: "text",
                          // WebkitBackgroundClip: "text",
                          // WebkitTextFillColor: "transparent",
                          color: "rgb(237, 184, 156)",
                        }
                      : {};
                  }}
                >
                  {pages}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      ) : (
        <></>
      )}
      {/* =============Wrapper For small Screens =================================== */}
      <div className="ScrollWatcher"></div>
    </div>
  );
};
export default Header;
