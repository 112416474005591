import styled  from "styled-components";

const InputContainer=styled.div`
position: relative;
width: 400px;
height: 70px;
margin-bottom: 15px;
font-family: 'Poppins',sans-serif;
`;


export {InputContainer}