import Emailjs from "./Email";

const MailSent = (emails,setUpdate) => {
  var FormData = {
    "InputBox FirstName": "Anonymous",
    "InputBox LastName": "Anonymous",
    "InputBox email": emails,
    subject: "subcribe to New Letter",
    text_area: "sent from new letter subscribed text form",
  };
  Emailjs(FormData).then(
    function (response) {
      setUpdate((prev) => {
        setInterval(() => {
          window.location.reload();
        }, 1000);
        return {
          ...prev,
          status: 1,
          message: "Email Sent",
          color: "green",
        };
      });
    },
    function (error) {
      setUpdate((prev) => {
        setInterval(() => {
          window.location.reload();
        }, 1000);
        return {
          ...prev,
          status: -1,
          message: "Sent failed",
          color: "red",
        };
      });
    }
  );
};

export default MailSent