import "./contact.css";
import { Img } from "../../components/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import TextField from "../../components/textFeilds/TextFeild";
import Footer from "../../components/Footer/Footer";
import PageSegment from "../../components/PageSegment/PageSegment";
import { useEffect, useState } from "react";
import { SyncLoader } from "react-spinners";
import { Emailjs } from "../../services";

const Contact = () => {
  const [screenWidth, setScreenWidth] = useState(0);
  const [FormData, setFormData] = useState({});
  const [sentUpdate, setSentupdate] = useState({
    status: -2,
    message: "",
    bgColor: "",
    color: "",
  });

  useEffect(() => {
    function WindowSize() {
      console.log(window.innerWidth);
      setScreenWidth((props) => {
        return window.innerWidth;
      });
    }
    window.addEventListener("resize", WindowSize);
    return () => window.removeEventListener("resize", WindowSize);
  }, []);

  const validateDetails = (FormData) => {
    if (
      FormData["InputBox FirstName"] &&
      FormData["InputBox LastName"] &&
      FormData["InputBox email"] &&
      FormData["text_area"]
    ) {
      return true;
    } else return false;
  };
  const handleFeildChange = (e) => {
    if (e.target.className === "SendButton") {
      if (validateDetails(FormData)) {
        e.preventDefault();
        setSentupdate((prev) => {
          return { ...prev, status: 0 };
        });
        Emailjs(FormData).then(
          function (response) {
            setSentupdate((prev) => {
              setInterval(() => {
                window.location.reload();
              }, 1000);
              return {
                ...prev,
                status: 1,
                message: "Message Sent",
                bgColor: "rgb(25, 135, 84)",
                color: "white",
              };
            });
          },
          function (error) {
            setSentupdate((prev) => {
              setInterval(() => {
                window.location.reload();
              }, 1000);
              return {
                ...prev,
                status: -1,
                message: "Sent failed",
                bgColor: "lightred",
                color: "white",
              };
            });
          }
        );
      } else {
      }
    } else {
      setFormData((prev) => {
        return { ...prev, [e.target.className]: e.target.value };
      });
    }
  };

  return (
    <div className="ContactUs">
      <PageSegment name="Contact" />
      <div className="Contact-Content">
        <div className="Get-In-Touch">
          <div className="GIT-Contatiner">
            <div className="GIT-Text">
              <div>
                Get in <label>Touch</label>
              </div>
              <div>Say something to start a live chat!</div>
            </div>
            <div className="GIT-Contact">
              <div className="Contact-Wrapper">
                <Img src="images/img_bxs_phone_call.svg" />
                <div>+91 989-104-1341</div>
              </div>
              <div className="Contact-Wrapper">
                <Img src="images/img_ic_sharp_email.svg" />
                <div>contact@altgan.com</div>
              </div>
              <div className="Contact-Wrapper">
                <Img src="images/img_carbon_location_filled.svg" />
                <div>
                  2100 Palmera Ridge Blvd, Austin 78641 United States
                </div>
              </div>
            </div>
          </div>
          <div className="Contact-Media">
            <a href="">
              {" "}
              <FontAwesomeIcon icon={faFacebook} size="xl" color="#74C0FC" />
            </a>
            <a href="">
              <FontAwesomeIcon icon={faInstagram} size="xl" color=" #8a3ab9" />
            </a>
            <a href="">
              <FontAwesomeIcon icon={faTwitter} size="xl" color=" #8a3ab9" />
            </a>
            <a href="">
              <FontAwesomeIcon icon={faLinkedin} size="xl" color="#0762C8" />
            </a>
          </div>
        </div>

        <form className="Contact-Fields">
          <div className="Name-Wrapper">
            <TextField
              width={"353.327px"}
              height="64.897px"
              name="FirstName"
              radius="6px"
              background="rgba(255, 255, 255, 0.19)"
              border="none"
              icon="flase"
              handleFeildChange={handleFeildChange}
            />
            <TextField
              width={"353.327px"}
              height="64.897px"
              name="LastName"
              radius="6px"
              border="none"
              background="rgba(255, 255, 255, 0.19)"
              icon="flase"
              handleFeildChange={handleFeildChange}
            />
          </div>
          <TextField
            width={"733.094px"}
            height="64.897px"
            name="email"
            radius="6px"
            border="none"
            background="rgba(255, 255, 255, 0.19)"
            icon="flase"
            handleFeildChange={handleFeildChange}
          />
          <div className="teaxtarea_wrapper">
            <textarea
              className="text_area"
              name="message"
              onChange={handleFeildChange}
              required
            ></textarea>
            <span>MEESSAGE</span>
          </div>

          <button
            className="SendButton"
            onClick={handleFeildChange}
            style={{
              color: sentUpdate.color,
              backgroundColor: sentUpdate.bgColor,
            }}
          >
            {sentUpdate.status == -2 ? (
              "Send Message"
            ) : sentUpdate.status == 0 ? (
              <SyncLoader color="#36d7b7" />
            ) : sentUpdate.status == 1 ? (
              "Message Sent"
            ) : (
              "Sent Failed"
            )}
          </button>
        </form>
      </div>
      <Footer screenWidth={screenWidth} />
    </div>
  );
};

export default Contact;
