import { Route, Routes } from "react-router-dom";
import { HFContainer } from "../pages/HFContaier/HFContainer";
import ScrollSetter from "../components/scrollsetter/scrollSetter";
import HomePage from "../pages/HomePage/HomePage";
import AboutUs from "../pages/AboutUs/AboutUs";
import Service from "../pages/ProductAndService/Service";
import WelcomePage4 from "../components/WelcomePage4/welcomePage4";
import Contact from "../pages/Contact/Contact";
import Underdevelopment from "../components/Underdevelopmnet/UnderDevelopment";
import Kayite from "../pages/ProductAndService/Kaytie";
import NewAndInsight from "../pages/Blogs/Blogs";
import BlogInfo1 from "../pages/Blogs/BlogInfo1";
import BlogInfo2 from "../pages/Blogs/BlogInfo2";
import BlogInfo3 from "../pages/Blogs/BlogInfo3";
import BlogInfo4 from "../pages/Blogs/BlogInfo4";
import BlogInfo5 from "../pages/Blogs/BlogInfo5";
import Blogs from "../pages/Blogs/Blogs";
import MultiviewORS from "../pages/ProductAndService/MultiviewORS";

const AppRoute = () => {
  return (
    <>
      <Routes>
        <Route replace path="/" element={<WelcomePage4 />} />
        <Route
          path="/home"
          element={
            <HFContainer PageName="Home">
              <HomePage />
            </HFContainer>
          }
        />
        <Route
          path="/About"
          element={
            <HFContainer PageName="About Us">
              <AboutUs />
            </HFContainer>
          }
        />
        <Route
          path="/Services"
          element={
            <HFContainer PageName="Product & Services">
              <Service />
            </HFContainer>
          }
        />
        <Route
          path="/Contact"
          element={
            <HFContainer PageName="Contact Us">
              <Contact />
            </HFContainer>
          }
        />
        <Route
          path="/Blogs"
          element={
            <HFContainer PageName="/Blogs">
              <Blogs />
            </HFContainer>
          }
        />
        <Route
          path="/Blogs/BlogInfo1"
          element={
            <HFContainer PageName="BlogInfo1">
              <BlogInfo1 />
            </HFContainer>
          }
        />
        <Route
          path="/Blogs/BlogInfo2"
          element={
            <HFContainer PageName="">
              <BlogInfo2 />
            </HFContainer>
          }
        />
        <Route
          path="/Blogs/BlogInfo3"
          element={
            <HFContainer PageName="">
              <BlogInfo3 />
            </HFContainer>
          }
        />
        <Route
          path="/Blogs/BlogInfo4"
          element={
            <HFContainer PageName="">
              <BlogInfo4 />
            </HFContainer>
          }
        />
        <Route
          path="/Blogs/BlogInfo5"
          element={
            <HFContainer PageName="">
              <BlogInfo5 />
            </HFContainer>
          }
        />
        <Route
          path="/Products/kaytie"
          element={
            <HFContainer PageName="Products_Kaytie">
              <Kayite />
            </HFContainer>
          }
        />
        <Route
          path="/Products/multiview"
          element={
            <HFContainer PageName="Products_Multiview">
              <MultiviewORS />
            </HFContainer>
          }
        />
      </Routes>
    </>
  );
};

export default AppRoute;
