import { Img } from "../../components/Image";
import "./aboutus.css";

const Mission = () => {
  return (
    <div className="Our-Mission">
      <div className="Our-Mission_logo">
        <Img src="/images/MissionImg.svg" />
      </div>
      <div className="Our-Mission-Context">
        <div className="Our-Mission-Content">
          <div className="Our-Mission-Title">Our Mission</div>
          <div className="Our-Mission-Header">
            <div>
              Empowering <label>Vision </label>&
            </div>
            <div>
              <label>Transforming</label> Communication
            </div>
          </div>
          <div className="Our-Mission-Phrase">
            We stand at the forefront of technological evolution, driven by a
            relentless commitment to deep learning expertise.
          </div>
          <div className="Our-Mission-CoreTitle">Our Core Pillars are:</div>
          <ul className="Mission-Points">
            <li>
              <div className="Our-Mission-Title">
                Multi Camera surveillance and Recognition Technology
              </div>
              <div className="Our-Mission-Subject">
                Enables seamless monitoring across multiple cameras/areas,
                ensuring comprehensive coverage and real-time recognition of
                objects/people. We built a pipeline of stereo, detection and
                multi view transformer algorithms to seamless operation and
                staying ahead of the trend in improvising continuously.
              </div>
            </li>
            <li>
              <div className="Our-Mission-Title">Enterprise LLM Skills</div>
              <div className="Our-Mission-Subject">
                Elevating enterprise intelligence, we provide Large Language
                Model (LLM) expertise tailored to optimize processes, drive
                innovation, and unlock the full potential of your data. We have
                industries’ best thought leaders and Engineers, who are
                committed to excellence in Enterprise LLM areas.
              </div>
            </li>
            <li>
              <div className="Our-Mission-Title">
                Deep learning model training and Deployment Services
              </div>
              <div className="Our-Mission-Subject">
                We go beyond the ordinary, offering comprehensive computer
                vision model training services to empower businesses with
                customized, high-performance models tailored to their unique
                needs.
              </div>
              <div className="Our-Mission-Subject">
                Decoding Needs and Encoding the solution is the motto we follow
                in our services. Committing to Excellence is the attitude that
                our clients love about us.
              </div>
            </li>
          </ul>
        </div>
        <div className="Our-Promises">
          <div className="Our-Promise-Tiitle">Our Promise</div>
          <div className="Our-Promise-Header">
            Innovation that <label>Transcends Boundaries, Expertise </label>that
            Redefines Excellence
          </div>
          <div className="Our-Promise-Subject">
            Join us in shaping a future where technology is not just a tool but
            a transformative force. Together, let's embark on a journey into
            thelimitless possibilities of deep learning and artificial
            intelligence.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
