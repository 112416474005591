import { useNavigate } from "react-router-dom";
import "./new&insight.css";

const BlogOtherArticals = (props) => {
  var rand;
  do {
    rand = Math.floor(Math.random() * 5) + 1;
  } while (rand == props.id);
  const nav = useNavigate();
  function BlogNavigation(e) {
    var id = e.target.id;
    var navUrl = `/Blogs/BlogInfo${id}`;
    nav(navUrl);
  }

  return (
    <div className="BlogOtherArticals">
      <div className="OthetAtricalsHeadind">Other Related Articals</div>
      <div className="OthetAtricalsSubHeadind">
        Exploring stories that resonate
      </div>
      <div className="OtherArticalsWrapper">
        {props.id == 1 || rand == 1 ? (
          ""
        ) : (
          <div className="Blog2" id="1" onClick={BlogNavigation}>
            <img src="/images/blogImg1.png" class="blogImg" id="1" />
            <div className="Blog2Context">
              <div className="Category">Category</div>
              <div className="BlogTitle">
                Deep Learning at the Edge: Making Big Brains Fit Tiny...
              </div>
              <div className="Author" id={1}>
                <img
                  src="/images/img_portrait_smilin.png"
                  width="52px"
                  height="50px"
                />
                <div className="AuthorName">M Shashi</div>
                <div className="PublishDate"> Jun 03, 2023 </div>
              </div>
            </div>
          </div>
        )}
        {props.id == 2 || rand == 2 ? (
          ""
        ) : (
          <div className="Blog2" id="2" onClick={BlogNavigation}>
            <img src="/images/blogImg2.png" class="blogImg" id="2" />
            <div className="Blog2Context">
              <div className="Category">Category</div>
              <div className="BlogTitle">
                Fusion of Vision, Speech, and Text: Transforming Business...
              </div>
              <div className="Author" id={2}>
                <img
                  src="/images/img_portrait_smilin.png"
                  width="52px"
                  height="50px"
                />
                <div className="AuthorName">M Shashi</div>
                <div className="PublishDate"> Jun 03, 2023 </div>
              </div>
            </div>
          </div>
        )}
        {props.id == 3 || rand == 3 ? (
          ""
        ) : (
          <div className="Blog2" id="3" onClick={BlogNavigation}>
            <img src="/images/blogImg3_1.png" class="blogImg" id="3" />
            <div className="Blog2Context">
              <div className="Category">Category</div>
              <div className="BlogTitle">
                Multi-Modal Neural Networks: Seeing Beyond the...
              </div>
              <div className="Author" id={3}>
                <img
                  src="/images/img_portrait_smilin.png"
                  width="52px"
                  height="50px"
                />
                <div className="AuthorName">M Shashi</div>
                <div className="PublishDate"> Jun 03, 2023 </div>
              </div>
            </div>
          </div>
        )}
        {props.id == 4 || rand == 4 ? (
          ""
        ) : (
          <div className="Blog2" id="4" onClick={BlogNavigation}>
            <img src="/images/blogImg4.png" class="blogImg" id="4" />
            <div className="Blog2Context">
              <div className="Category">Category</div>
              <div className="BlogTitle">
                Seeing More, Knowing More: How Multi-View...
              </div>
              <div className="Author" id={4}>
                <img
                  src="/images/img_portrait_smilin.png"
                  width="52px"
                  height="50px"
                  id="4"
                />
                <div className="AuthorName">M Shashi</div>
                <div className="PublishDate"> Jun 03, 2023 </div>
              </div>
            </div>
          </div>
        )}
        {props.id == 5 || rand == 5 ? (
          ""
        ) : (
          <div className="Blog2" id="5" onClick={BlogNavigation}>
            <img src="/images/blogImg3.png" class="blogImg" id="5" />
            <div className="Blog2Context">
              <div className="Category">Category</div>
              <div className="BlogTitle">
                Demystifying the Magic: How Large Language...
              </div>
              <div className="Author" id={5}>
                <img
                  src="/images/img_portrait_smilin.png"
                  width="52px"
                  height="50px"
                />
                <div className="AuthorName">M Shashi</div>
                <div className="PublishDate"> Jun 03, 2023 </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogOtherArticals;
