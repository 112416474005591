import "./pagesegment.css";

const PageSegment = (props) => {
  // const names = props.name.split(" ");
  // const first = names.shift();
  return (
    <div className="Page-Segment">
      <div className="Page-Header">
        <label>{props.name}</label>
        {/* {names.join("")} */}
      </div>
      <div className="Page-Navigation">
        {"Home /"} <label>{props.name}</label>
      </div>
    </div>
  );
};

export default PageSegment;
