import "./welcomePage4.css"
import wpv from"../../assets/wpv2.mp4"
import { Link } from "react-router-dom"

const WelcomePage4=()=>{
    return(
        <div className="WP4-hero">
            <video autoPlay loop muted playsInline className="WP4-video">
                <source src={wpv} type="video/mp4" />
            </video>

            <div className="WP4-content">
                <h1>Welcome To ALTGAN</h1>
                <Link to={"/home"} className="a">
              Explore
            </Link>
            </div>
        </div>
    )
}

export default WelcomePage4