import { useState } from "react";
import "./TextFeild.css";
import { InputContainer } from "./textfeild_style";
import { MailSent } from "../../services";
import { SyncLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faFaceSadTear } from "@fortawesome/free-regular-svg-icons";
const TextField = (props) => {
  const [SentUpdate, setSentUpdate] = useState({
    status: -2,
    message: "",
    color: "",
  });
  const [email, setEmail] = useState("");
  const handelSent = (e) => {
    if (e.target.className === "InputLogo") {
      if (email !== "") {
        setSentUpdate((prev) => {
          return { ...prev, status: 0 };
        });
        MailSent(email, setSentUpdate);
      }
    } else {
      setEmail((prev) => {
        return e.target.value;
      });
    }
  };
  return (
    <InputContainer
      style={{ width: props.width, height: props.height, margin: props.margin }}
      className="InputContainer"
    >
      <input
        className={`InputBox ${props.name}`}
        type="text"
        required
        onChange={props.onlyMail ? handelSent : props.handleFeildChange}
        style={{
          borderRadius: `${props.radius}`,
          background: props.background,
          border: props.border,
          borderRadius: `${props.borderRadius}`,
        }}
        placeholder={SentUpdate.message}
      />
      <span
        className="InputName"
        style={{ left: props.NameLPostion, top: props.NameTPostion }}
      >
        {props.name}
      </span>
      {props.icon ? (
        ""
      ) : SentUpdate.status == -2 ? (
        <img
          src={"/images/EmailImg.svg"}
          className="InputLogo"
          onClick={handelSent}
        />
      ) : SentUpdate.status == 0 ? (
        <SyncLoader color="#FFFF00" className="SyncLogo" />
      ) : SentUpdate.status == 1 ? (
        <FontAwesomeIcon
          icon={faCheck}
          color="green"
          size="2xl"
          className="InputLogo"
        />
      ) : (
        <FontAwesomeIcon
          icon={faFaceSadTear}
          color="#FFFF00"
          className="InputLogo"
        />
      )}
    </InputContainer>
  );
};

export default TextField;
