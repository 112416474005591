import { useNavigate } from "react-router-dom";
import { Img } from "../../components/Image";
import PageSegment from "../../components/PageSegment/PageSegment";
import "./new&insight.css";

const Blogs = () => {
  const nav = useNavigate();
  function BlogNavigation(e) {
    var id = e.target.id;
    var navUrl = `/Blogs/BlogInfo${id}`;
    nav(navUrl);
  }
  return (
    <div className="NewAndInsight-Wrapper">
      <PageSegment name="Blogs" />
      <div className="DailyDiscoveries">
        <div className="DailyTitle">Daily Discoveries</div>
        <div className="DailySubTitle">New Things We Learn</div>
        <div className="BlogWrapper">
          <div className="Blog1" id="1" onClick={BlogNavigation}>
            <img src="images/blogImg1.png" class="blogImg1" id="1" />
            <div className="Blog1Context">
              <div className="Category">Category</div>
              <div className="BlogTitle">
                Deep Learning at the Edge: Making Big Brains Fit Tiny...
              </div>
              <div className="Author">
                <img
                  src="images/img_portrait_smilin.png"
                  width="52px"
                  height="50px"
                />
                <div className="AuthorName">M Shashi</div>
                <div className="PublishDate"> Jun 03, 2023 </div>
              </div>
            </div>
          </div>
          <div className="Blog2" id="2" onClick={BlogNavigation}>
            <img src="images/blogImg2.png" class="blogImg" id="2" />
            <div className="Blog2Context">
              <div className="Category">Category</div>
              <div className="BlogTitle">
                Fusion of Vision, Speech, and Text: Transforming Business...
              </div>
              <div className="Author">
                <img
                  src="images/img_portrait_smilin.png"
                  width="52px"
                  height="50px"
                />
                <div className="AuthorName">M Shashi</div>
                <div className="PublishDate"> Jun 03, 2023 </div>
              </div>
            </div>
          </div>
          <div className="Blog2" id="3" onClick={BlogNavigation}>
            <img src="images/blogImg3_1.png" class="blogImg" id="3" />
            <div className="Blog2Context">
              <div className="Category">Category</div>
              <div className="BlogTitle">
                Multi-Modal Neural Networks: Seeing Beyond the...
              </div>
              <div className="Author">
                <img
                  src="images/img_portrait_smilin.png"
                  width="52px"
                  height="50px"
                />
                <div className="AuthorName">M Shashi</div>
                <div className="PublishDate"> Jun 03, 2023 </div>
              </div>
            </div>
          </div>
          <div className="Blog2" id="4" onClick={BlogNavigation}>
            <img src="images/blogImg4.png" class="blogImg" id="4" />
            <div className="Blog2Context">
              <div className="Category">Category</div>
              <div className="BlogTitle">
                Seeing More, Knowing More: How Multi-View...
              </div>
              <div className="Author">
                <img
                  src="images/img_portrait_smilin.png"
                  width="52px"
                  height="50px"
                  id="4"
                />
                <div className="AuthorName">M Shashi</div>
                <div className="PublishDate"> Jun 03, 2023 </div>
              </div>
            </div>
          </div>
          <div className="Blog2" id="5" onClick={BlogNavigation}>
            <img src="images/blogImg3_1.png" class="blogImg" id="5" />
            <div className="Blog2Context">
              <div className="Category">Category</div>
              <div className="BlogTitle">
                Demystifying the Magic: How Large Language...
              </div>
              <div className="Author">
                <img
                  src="images/img_portrait_smilin.png"
                  width="52px"
                  height="50px"
                />
                <div className="AuthorName">M Shashi</div>
                <div className="PublishDate"> Jun 03, 2023 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
