import ProfileCard from "../../components/profileCard/ProfileCard";

const Team = () => {
  const data = require("./TeamData.json");
  return (
    <div className="Our-Team">
      <div className="Our-Team-Header">
        Meet Our <label>Leadership</label>
      </div>
      <div className="Our-Team-SubHeading">
        <div>
          United by their shared vision,
          strengthened by collective skillset and going
        </div>
        <div> strong with our commitment to excellence.</div>
      </div>
      <div className="Our-Team-Founders"></div>
      <div className="Our-Team-AvtarContainer">
        <ProfileCard data={data[1]} />
        <ProfileCard data={data[2]} />
        <ProfileCard data={data[3]} />
      </div>
      {/* <div className="Our-Team-Founders">Founding Members</div>
      <div className="Our-Team-AvtarContainer">
        <ProfileCard data={data[4]} />
        <ProfileCard data={data[5]} />
        <ProfileCard data={data[6]} />
      </div> */}
    </div>
  );
};

export default Team;
