import { Img } from "../../components/Image";

const Insights = () => {
  return (
    <div className="Our-Insights">
      <div className="Insight-Info">
        <Img
          src="images/potentialImg2.png"
          width="88px"
          height="88.813px"
          className={"ZoomEye"}
        />
        <div className="Insight-Header">
          <div>Multi view object</div> <div>recognition system</div>
        </div>
        <div className="Potential-Text">
          Brings you the ability of recognizing and or monitoring people,objects
          through AI assisted multi camera coordination.
        </div>
        {/* <div>
            Our team has put together different pipelines of stereo,
            segmentation, vision transformer and several other AI algorithms
            using vast experience. Can differentiate minor difference between
            the objects (like flavor change in chocolates, color, shape font
            changes on the objects)
          </div> */}
        <ul className="Potential-Bullets">
          <li>
            Put together different pipelines of stereo, segmentation, vision
            transformer and several other AI algorithms
          </li>
          {/* <li>
              Democratization of business knowledge within teams / organizations /
              Enterprises
            </li> */}
          <li>Detect, track and monitors the objects in real-time.</li>
          <li>
            Can differentiate minor difference between the objects such as
            shape, size, font etc.
          </li>
        </ul>
        <a
          href="/Products/multiview"
          style={{ textDecoration: "none" }}
          className="KaytieButton"
        >
          Read More
        </a>
        {/* <ul className="Insight-Bullets">
          <li> Work Through</li>
          <li>24/7 Support</li>
          <li>Professional Staff</li>
          <li>Fair Prices.</li>
        </ul> */}
      </div>
      <div className="Insights-ImgContainer">
        <Img src="images/serviceImg3.png" />
      </div>
    </div>
  );
};

export default Insights;
