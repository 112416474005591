
import './App.css';
import AppRoute from './routes';


function App() {
  return (
   <AppRoute />
  );
}

export default App;


