import Footer from "../../components/Footer/Footer";
import { Img } from "../../components/Image";
import PageSegment from "../../components/PageSegment/PageSegment";
import Mission from "./Mission";
import Team from "./Team";
import "./aboutus.css";

const AboutUs = () => {
  return (
    <div className="AB-Wrapper">
      <PageSegment name="About Us" />
      {/* <div className="Our-Vision">
        <div className="Our-Vision-Container">
          <div className="Our-Vision-Wrapper">
            <div className="Our-Vision-Content">
              <div className="Our-Vision-Context">
                <div className="Our-Vision-Title">Our Vision</div>
                <div className="Our-Vision-Heading">
                  Make Your <label>Business Smarter</label> with your Solution
                </div>
                <div className="Our-Vision-Body">
                  <div>
                    We envision unlocking unparalleled efficiency by enabling
                    businesses to harness their multi-modal data.(real
                    timevideo, data, documents and audio).
                  </div>
                  <div>
                    Our ultimate goal is to make humanoid robots for business
                    needs.
                  </div>
                  <div>
                    Fun and simplicity are at the heart of everything we do.
                    We're passionate about creating products that enhancelives,
                    bring joy to the daily routines of our Enterprise users.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Our-Vision-Img">
            <Img src="images/ourvisionImg.svg" />
          </div>
        </div>
      </div> */}
      <div className="Our-Vision">
        <div className="Our-Vision-Context">
          <div className="Our-Vision-Title">Our Vision</div>
          <div className="Our-Vision-Heading">
            <div>
              Make Your <label>Business Smarter</label>
            </div>
            <div>with our Solution</div>
          </div>
          <div className="Our-Vision-Body">
            <div>
              We envision unlocking unparalleled efficiency by enabling
              businesses to harness their multi-modal data (real-time video,
              documents, and audio).
            </div>
            <div>
              Our ultimate goal is to make humanoid robots for business needs.
            </div>
            <div>
              Fun and simplicity are at the heart of everything we do. We're
              passionate about creating products that enhancelives, bring joy to
              the daily routines of our Enterprise users.
            </div>
          </div>
        </div>
        <div className="Our-Vision-Img">
          <Img src="images/Layer_1.png" />
        </div>
      </div>
      <Mission />
      <Team />
      <Footer WrappermarginBS="3rem" />
    </div>
  );
};

export default AboutUs;
