import Footer from "../../components/Footer/Footer";
import TextField from "../../components/textFeilds/TextFeild";
import "./productandservice.css";

const MultiviewORS = () => {
  return (
    <div className="Kaytie-Wrapper">
      <div className="kayite-Container">
        <div className="Kayite-content">
          <div className="Kaytie-Header-Wrapper">
            <div className="Kaytie-Header">Ezy checkout</div>
            <div className="Kaytie-Subtext">
            Introducing Multi-View Retail Quick Checkout Recognition
            </div>
          </div>
          <div className="WelcomeKaytie">
            <div>
            Streamline Your Checkout Process and Revolutionize your checkout experience with our innovative multi-view retail quick checkout recognition system
            </div>
            <div>
            Eliminate long lines and enhance customer satisfaction with this cutting-edge technology
            </div>
          </div>
          {/* <div className="WithKaytie">
            Functionality:
          </div> */}
          <div className="KaytieGenAi">
            <div>
            <p style={{fontWeight: 1000}}>Multiple Cameras Capture Every Angle:</p> Strategically placed cameras create a 360-degree view of the checkout area, capturing all items effortlessly
            </div>
            <div>
            <p style={{fontWeight: 1000}}>Advanced Recognition Technology:</p> Our sophisticated algorithms seamlessly identify and track every item placed on the checkout counter, eliminating the need for manual scanning
            </div>
            <div>
            <p style={{fontWeight: 1000}}>Lightning-Fast Processing:</p> Items are recognized and registered instantaneously, allowing for swift and efficient checkout transactions
            </div>
          </div>
          <div className="Kaytie-why">Why Us:</div>
          <ul className="Kaytie-bullets">
            <li>
            Eliminate long lines and enhance customer satisfaction with faster checkouts
            </li>
            <li>Free up staff for more customer-centric tasks like assisting with product selection or inquiries</li>
            <li>
              Efficiency
              <br />
              Our pipelines are well designed to get the quickest predictions in real-time to improve user experience
            </li>
            <li>
              Accuracy
              <br />
              We ensure the best results by using state-of-the-art models to identify and track items.
            </li>
            <li>
            Gain insights into customer shopping behavior through real-time data analysis
            </li>
          </ul>
        </div>
        <div className="KaytieImgcontainer">
          <img src="/images/KaytieImg.svg" />
        </div>
      </div>
        <div className="Kaytie-SubscribeNote">
          <div className="Kaytie-SubscribeNoteContainer">
            <div className="Kaytie-SubscribeNote_Header">
              Subscribe to our <label>Newsletter</label>
            </div>
            <div className="Kaytie-SubscribeNote_Text">
            Sign up for our updates and be the first to know about latest AI solutions
            </div>
            <TextField
              name="enter your email"
              width="400px"
              height="60px"
              onlyMail={true}
            />
          </div>
      </div>
      <Footer  />
    </div>
  );
};

export default MultiviewORS;
