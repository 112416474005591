import React from "react";

import "./footer.css";
import { Img } from "../Image";
import TextField from "../textFeilds/TextFeild";

const Footer = (props) => {
  const myStyle = {
    // backgroundImage: `url("images/${props.bg1}"),url("images/${props.bg2}")`,
    // backgroundSize: props.bgSize,
    // backgroundRepeat: props.bgRepeat,
    // backgroundClip: props.backgroundClip,
    // backgroundPosition: props.bgPosition,
    // backgroundColor:props.bgColor,
    // backgroundBlendMode:props.blendMode,
    // paddingBlockStart:props.PBstar
    marginBlockStart: props.WrappermarginBS,
  };
  return (
    <div className="footer-Wrapper" style={myStyle}>
      <div className="footer-Container">
        <div className="footer-section1">
          <Img
            className="logo"
            src="/images/altgan-logo.svg"
            height="78.62px"
            width="161px"
          />

          <div className="footer-section-text">
            We are a solutions provider for AI-powered visual recognition
            systems, Intelligent Assistants and insights extraction for
            businesses
          </div>
          <nav>
            <ul>
              <li>
                <a href="#">
                  <Img src="/images/img_facebook_original.svg" />
                </a>
              </li>
              <li>
                <a href="#">
                  <Img src="/images/img_instagram_original.svg" />
                </a>
              </li>
              <li>
                <a href="#">
                  <Img src="/images/img_twitter_original.svg" />
                </a>
              </li>
              <li>
                <a href="#">
                  <Img src="/images/img_vector.svg" />
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="footer-section2">
          <div className="footer-section2-header">Our Company</div>
          <ul>
            <li>About Us</li>
            <li> Agents</li>
            <li>Blogs</li>
            <li> Media</li>
            <li>Contact Us</li>
          </ul>
        </div>
        <div className="footer-section3">
          <div className="footer-section3-header"> Subscribe</div>
          <div>
            Subscribe for latest AI breakthroughs and pioneering products
          </div>
          <TextField
            height="60px"
            width={"369.333px"}
            name="email address"
            borderRadius="15px"
            onlyMail={true}
          />
          <div>&copy; 2024 by Altgan Pvt. Ltd </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
