import React, { useEffect, useState } from "react";
import "./homepage.css";
import { Img } from "../../components/Image";
import TextField from "../../components/textFeilds/TextFeild";
import Footer from "../../components/Footer/Footer";

const HomePage = () => {
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    function WindowSize() {
      console.log(window.innerWidth);
      setScreenWidth((props) => {
        return window.innerWidth;
      });
    }
    window.addEventListener("resize", WindowSize);
    return () => window.removeEventListener("resize", WindowSize);
  }, []);
  return (
    <div className="HP-Wrapper">
      <div className="HP-Section1">
        <div className="HP-STextWrapper">
          {/* <div className="HP-S1T1">Re-invent your businesses with AI-driven</div>
          <div className="HP-S1T2">
            <label>Knowledge retrieval</label> and Intelligent sensing platforms
          </div> */}
          <div className="HP-S1T1">Knowledge & Vision.</div>
          <div className="HP-S1T2">
            The <label>AI advantage</label> for your business.
          </div>
          <div className="HP-S1ST">
            <div>
              Unlock your Business Potential through our Deep vision on Edge and
            </div>
            <div>Enterprise LLM Expertise.</div>
          </div>
          <a className="HP-S1L" href="/contact">
            Contact Us
          </a>
        </div>
        <div className="HP-S1ST2">Scroll Down</div>
      </div>

      {/*------------------------------------------------------------------------------------------------------------------------------------------------------- */}
      {/*--------------------------------------------------------------------------------------------------------------------------------------------------------*/}
      {/*--------------------------------------------------------------------------------------------------------------------------------------------------------*/}

      <div className="HP-Section2">
        <div className="HP-S2TitleWrapper">
          <div className="HP-S2T1">AltGAN</div>
          <div className="HP-S2T2">We ensure our expertise is</div>
          <div className="HP-S2T3">
            <label> Beyond Boundaries, </label>Ahead of Trends
          </div>
        </div>
        <div className="HP-S2ST1">Step into AltGAN</div>

        <div className="HP-S2Content">
          <div className="HP-S2CSection1">
            <div className="HP-S2CS1Container1">
              <Img src="/images/vision1.svg" className="ListStyle" />
              <div className="HP-S2CS1TextWrapper1">
                <div>
                  Unleashing the Potential <br />
                  of Computer Vision
                </div>
                {/* <div className="HP-S2CS1SubText">
                  orem Ipsum has been the industry's standard dummy text ever
                  since the 1500s. Lorem Ipsum has been the industry's standard.
                </div> */}
              </div>
            </div>
            <div className="HP-S2CS1Container2">
              <Img src="/images/vision2.svg" className="ListStyle" />
              <div className="HP-S2CS1TextWrapper2">
                <div>
                  Leverage language models for unparalleled digital transformations
                </div>
                {/* <div className="HP-S2CS1SubText">
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s. Lorem Ipsum has been the industry's standard.
                </div> */}
              </div>
            </div>
            <div className="HP-S2CS1Container3">
              <Img src="/images/vision3.svg" className="ListStyle" />
              <div className="HP-S2CS1TextWrapper3">
                <div>Where Innovation Converges with Intelligence</div>
                {/* <div className="HP-S2CS1SubText">
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s. Lorem Ipsum has been the industry's standard.
                </div> */}
              </div>
            </div>
          </div>

          <div className="HP-S2CS2Container">
            <Img src="/images/Frame.svg" />
          </div>
        </div>
      </div>
      {/*------------------------------------------------------------------------------------------------------------------------------------------------------- */}
      {/*--------------------------------------------------------------------------------------------------------------------------------------------------------*/}
      {/*--------------------------------------------------------------------------------------------------------------------------------------------------------*/}

      <div className="HP-AboutUs">
        <div className="HP-AboutUs_Img_Contianer">
          <Img src="/images/HomePageAboutUs.svg" className="HP-AboutUs_Img" />
        </div>
        <div className="HP-AboutUs_Content">
          <div className="HP-AboutUs_Title">
            <label>Industries</label>
          </div>
          <ul className="HP-AboutUs_SubText">
            <li>
              We serve to businesses such as Retail, EdTech, 
              Manufacturing Industries and serve a multitude of other sectors
              with our best vision solutions using AI-powered monitoring and tracking.
            </li>
            <li>
            Our language services empower industries such as Oil and Gas, Finance, EdTech and many other sectors
             to turn complex documents and data into actionable insights.
            </li>
          </ul>
          <a className="HP-AboutUs_Link" href="/contact">
            Contact Us
          </a>
        </div>
      </div>

      {/*------------------------------------------------------------------------------------------------------------------------------------------------------- */}
      {/*--------------------------------------------------------------------------------------------------------------------------------------------------------*/}
      {/*--------------------------------------------------------------------------------------------------------------------------------------------------------*/}
      <div className="HP-AboutUs">
        
        <div className="HP-AboutUs_Content">
          <div className="HP-AboutUs_Title">
            <label>Solutions</label>
          </div>
          {/* <div className="HP-AboutUs_SubText">
            <div className="HP-Catering_TextOne">
              Catering to a diverse clientele, from startups to enterprises, we
              specialize in customizing our integration services to meet your
              unique needs
            </div>
            <div className="HP-Catering_TextTwo">
              We design user-friendly applications and efficient pipelines using
              state-of-the-art models, ensuring leadership in the evolving
              digital landscape
            </div>
          </div> */}
          <ul className="HP-AboutUs_SubText">
            <li>
            Catering to a diverse clientele, from startups to enterprises, we 
            specialize in customizing our integration services to meet your 
            unique needs.
            </li>
            <li>
            We design user-friendly applications and efficient pipelines using
            state-of-the-art models, ensuring leadership in the evolving
            digital landscape.
            </li>
          </ul>
        </div>
        <div className="HP-Catering_Img_Contianer">
          <Img src="/images/LastSlide.svg" height="500px" />
        </div>
      </div>

      {/*------------------------------------------------------------------------------------------------------------------------------------------------------- */}
      {/*--------------------------------------------------------------------------------------------------------------------------------------------------------*/}
      {/*--------------------------------------------------------------------------------------------------------------------------------------------------------*/}
      <div className="HP-SubscribeNote">
        <div className="HP-SubscribeNoteContainer">
          <div className="HP-SubscribeNote_Header">
            Subscribe to our <label>Newsletter</label>
          </div>
          <div className="HP-SubscribeNote_Text">
            Sign up for our updates and be the first to
            <div>know about latest AI solutions</div>
          </div>
          {/* <div className="HP-SubscribeNote_Text">
           No Spam, we promise 
          </div> */}
          <TextField
            name="enter your email"
            width={"380px"}
            height="60px"
            borderRadius="20px"
            onlyMail={true}
          />
        </div>
      </div>
      <Footer
        WrappermarginBS={
          screenWidth >= 0 && screenWidth <= 600 ? "2rem" : "4rem"
        }
        screenWidth={screenWidth}
      />
    </div>
  );
};

export default HomePage;
