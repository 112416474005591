import Footer from "../../components/Footer/Footer";
import TextField from "../../components/textFeilds/TextFeild";
import "./productandservice.css";

const Kayite = () => {
  return (
    <div className="Kaytie-Wrapper">
      <div className="kayite-Container">
        <div className="Kayite-content">
          <div className="Kaytie-Header-Wrapper">
            <div className="Kaytie-Header">Kaytie</div>
            <div className="Kaytie-Subtext">
              Revolutionizing Enterprise Knowledge Retrieval
            </div>
          </div>
          <div className="WelcomeKaytie">
            <div>
              Welcome to Kaytie, your trusted partner in harnessing the power of
              AI <br />
              for enterprise knowledge Retrieval. leverages{" "}
            </div>
            <div>
              We believe in making knowledge retreival seamless as a google
              search leveraging Generative AI and LLM models
            </div>
            <div>Generative AI and LLM models</div>
          </div>
          <div className="WithKaytie">
            With Kaytie, we envision a future where every enterprise, regardless
            of its size <br />
            or industry, can effortlessly tap into its vast knowledge base.
          </div>
          <div className="KaytieGenAi">
            <div>
              GenAI is designed to understand, learn, and reason like a human,
              but at a scale
              <br /> that’s beyond human capabilities. It can process vast
              amounts of data, providing <br />
              you with actionable insights.{" "}
            </div>
            <div>
              Our large language models are trained on diverse datasets,
              enabling them to <br />
              understand and generate human-like text.
            </div>
            <div>
              Combination of these two help us to comprehend documents, emails,
              reports, and other text data, and retrieve relevant information in
              real-time.
            </div>
          </div>
          <div className="Kaytie-why">Why Us:</div>
          <ul className="Kaytie-bullets">
            <li>
              Personalized information retreival based on User’s access to docs.
            </li>
            <li>Tagging the sources with document timelines and versions. s</li>
            <li className="ListHeading">Efficiency</li>
            <li>
              Say goodbye to hours spent searching for information. With GenAI,
              you get what you need in seconds.{" "}
            </li>
            <li className="ListHeading">Accuracy</li>
            <li>
              Our AI doesn’t just work fast, it works right. Expect highly
              accurate results tailored to your query.
            </li>
            <li className="ListHeading">Scalability</li>
            <li>
              Our solutions grow with your business. No matter how much your
              data expands, GenAI can handle it.
            </li>
          </ul>
        </div>
        <div className="KaytieImgcontainer">
          <img src="/images/KaytieImg.svg" />
        </div>
      </div>
      <div className="Kaytie-SubscribeNote">
        <div className="Kaytie-SubscribeNoteContainer">
          <div className="Kaytie-SubscribeNote_Header">
            Subscribe to our <label>Newsletter</label>
          </div>
          <div className="Kaytie-SubscribeNote_Text">
            Sign up for our updates and be the first to
            <div> know about latest AI solutions</div>
          </div>
          <TextField
            name="enter your email"
            width="360px"
            height="60px"
            borderRadius="20px"
            onlyMail={true}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Kayite;
